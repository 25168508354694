import React, { useState } from 'react';
import Wrap from '~/components/Wrap';
import SwitchTheme from '~/components/SwitchTheme';
import Headline from '~/components/Headline';

// import { Input } from 'theme-ui';

import { SectionPassword } from './PasswordProtection.styled';

export default ({ children, password }) => {
  const [passwordCorrect, setPasswordCorrect] = useState(false);

  const checkPassword = (e) => {
    return setPasswordCorrect(password === e.target.value);
  };

  return !password || passwordCorrect ? (
    children
  ) : (
    <SwitchTheme>
      <SectionPassword>
        <Wrap>
          <Headline>Password</Headline>
          <input
            type="password"
            onChange={checkPassword}
            style={{
              background: 'transparent',
              border: 0,
              borderBottom: '1px solid white',
              color: 'white',
              lineHeight: 1.5,
              fontSize: 20,
              padding: '20px 0',
              outline: 'none',
            }}
          />
        </Wrap>
      </SectionPassword>
    </SwitchTheme>
  );
};
