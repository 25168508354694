import React from 'react';
import Layout from '~/components/Layout';
import Sections from '~/components/Sections';
import PasswordProtection from '~/components/PasswordProtection';

export default ({ pageContext }) => {
  const {
    content,
    layout,
    seo,
    socials,
    cookieConsent,
    password,
  } = pageContext;

  // these have to be destructured and backed by hand since it can be that these
  // variables might not've been set in sanity cms by default. also we have to hand them
  // throu as single variables to make sure the site doesnt break in edge cases. ~DH
  const hasHeader = layout?.hasHeader || true;
  const hasFooter = layout?.hasFooter || true;

  return (
    <Layout
      hasHeader={hasHeader}
      hasFooter={hasFooter}
      seo={seo}
      socials={socials}
      cookieConsent={cookieConsent}
    >
      <PasswordProtection password={password}>
        {content && (
          <Sections
            content={content}
            layout={layout}
            hasHeader={hasHeader}
            hasFooter={hasFooter}
          />
        )}
      </PasswordProtection>
    </Layout>
  );
};
